// 测试
// export const serverName = 'parkcloud.test.szzcloud.club'
// export const payServerName = 'paycloud.test.szzcloud.club'
// export const codeUrl = 'https://coupon.test.szzcloud.club'
// export const emqxServerName = 'parkcloud.test.szzcloud.club'
// export const iotServerName = 'http://iot.test.szzcloud.club'
// export const cityCloudUrl = 'http://citycloud.test.szzcloud.club'

// 生产
export const serverName = 'parkcloud.szzcloud.club'
export const payServerName = 'paycloud.szzcloud.club'
export const codeUrl = 'https://coupon.szzcloud.club'
export const emqxServerName = 'parkcloud.szzcloud.club'
export const iotServerName = 'https://iot.szzcloud.club'
export const cityCloudUrl = 'https://citycloud.nayuntec.cn'

// 展示广告 ‘1’云纵 ‘2’伯斯特
// 必须是字符串 1，2，3
export const adFlag = '1'
